




export default function About() {
  return (
    <div className="">
      {/* About section Banner */}
      <div className="h-screen py-32  bg-black mx-auto flex flex-col items-center justify-center">
        <div className="text-white text-center">
            <h2 className="text-4xl lg:text-7xl lg:leading-snug font-bold mb-5">About us Section</h2>
        </div>
      </div>

        
     </div>
  )
}
